<template>
  <section class="relative">
    <ul
      ref="parentTab"
      class="Classification-inner sm:p-9px p-4px overflow-x-scroll rounded-32px flex items-center"
    >
      <li
        v-for="item in list"
        :key="item.label"
        ref="myTab"
        class="py-10px rounded-24px game-info cursor-pointer px-14px font-500 <sm:py-7px <sm:px-10px <sm:text-14px @sm:py-7px @sm:px-10px text-14px flex flex-shrink-0 items-center"
        :class="{ 'game-info-active': isGameInfoActive(item) }"
        @click="change(item)"
      >
        <CommonAsset v-if="item.icon" class="mr-8px" :name="item.icon" />
        <span>{{ item.label ?? item.title }}</span>
        <span
          v-if="item.count"
          class="flex show-count rounded-4px text-12px ml-2px p-2px"
          >{{ item.count }}</span
        >
      </li>
    </ul>
    <div
      ref="CursorGuideWrapRef"
      v-if="isShowCursorGuide"
      class="CursorGuideWrap animate__animated animate__fadeInLeft animate__delay-1s animate__repeat-1"
    >
      <CommonAsset
        name="images-common-hand-cursor-left-right"
        class="CursorGuide"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { unref } from "vue"
import { CommonAsset } from "#components"
import { useWindowSize } from "@vueuse/core"
const { t } = useLang()

type ListItem = {
  label: string
  icon?: string
  value: number | string
  count?: number
  title?: string
  code?: string
}
const props = defineProps<{
  list?: ListItem[]
  modelValue: string | number
  isSelectPosition?: boolean
}>()
const parentTab = ref<HTMLDivElement>()
const myTab = ref<HTMLLIElement[]>()
const emit = defineEmits(["update:modelValue", "change"])
const change = (item: ListItem) => {
  emit("update:modelValue", item.value)
  emit("change", { ...item, scrollLeft: unref(parentTab)?.scrollLeft })
}
const route = useRoute()

const selectPosition = () => {
  const nameList =
    (props.list?.map((_item) => {
      switch (_item.value) {
        case "Information":
          return "Information-List"
        default:
          return _item.value
      }
    }) as RouteName[]) ?? []
  const __idx = nameList.indexOf(props.modelValue.toString() as RouteName)

  if (__idx !== -1) {
    if (parentTab.value && myTab.value) {
      const element = myTab.value[__idx]
      const parentContainer = parentTab.value
      const elementWidth = element.offsetWidth
      const parentWidth = parentContainer.offsetWidth
      const centerOffset = (parentWidth - elementWidth) / 2
      const targetOffsetLeft =
        element.offsetLeft - parentContainer.offsetLeft - centerOffset
      // 获取并转换上次滚动的位置
      const start = Number(route.query.scrollLeft) || 0
      const end = targetOffsetLeft
      const duration = 500
      const startTime = performance.now()

      // 先直接跳到上次的滚动位置
      parentContainer.scrollLeft = start

      const scrollStep = (timestamp: any) => {
        const progress = Math.min(1, (timestamp - startTime) / duration)
        parentContainer.scrollLeft = start + (end - start) * progress
        if (progress < 1) {
          requestAnimationFrame(scrollStep)
        }
      }
      requestAnimationFrame(scrollStep)
    }
  }
}

onMounted(() => {
  if (props.isSelectPosition) selectPosition()
})

function isGameInfoActive(item: ListItem) {
  const route = useRoute()
  switch (item.value) {
    case "Information":
      return [
        route.name === "Information",
        route.name === "Information-List",
        route.name === "Information-ID",
      ].some((item) => item)
    default:
      return props.modelValue === item.value
  }
}

const CursorGuideWrapRef = ref<HTMLElement>()
const { width } = useWindowSize()
const isShowCursorGuide = computed(() => {
  return width.value < 679
})
watch(CursorGuideWrapRef, (el) => {
  if (el) {
    setTimeout(() => {
      el.classList.remove("animate__delay-1s", "animate__repeat-1")
      el.classList.add("animate__fadeOutRight")
      setTimeout(() => {
        el.remove()
      }, 1000)
    }, 3000)
  }
})
</script>
<style lang="scss" scoped>
@mixin active {
  color: var(--v-class-game-active-color);
  background: linear-gradient(180deg, #323443 0%, #262836 100%);
  box-shadow: 0px 2px 4px #1a1f26;
}

.Classification-inner {
  background: var(--v-class-inner-bg);
  width: fit-content;
  max-width: 100%;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .game-info {
    svg {
      opacity: 0.6;
    }

    color: var(--v-class-game-color);

    &-active {
      @include active;

      svg {
        opacity: 1;
      }
    }
  }

  .show-count {
    background: #df342d;
    color: #fff;
  }
}
.CursorGuideWrap {
  position: absolute;
  width: 3em;
  height: 3em;
  right: 1em;
  bottom: -70%;
  .CursorGuide {
    position: relative;
    /* display: none; */
    transform: scale(2);
    width: 100%;
    height: 100%;
  }
}
</style>
